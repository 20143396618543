.stripe-input {
  background-color: #fff;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  color: #363636;
  box-shadow: inset 0 0.0625em 0.125em rgba(10, 10, 10, 0.05);
  max-width: 100%;
  width: 100%;
  display: inline-flex;
  font-size: 1rem;
  height: 2.5em;
  justify-content: flex-start;
  line-height: 1.5;
  padding-bottom: calc(0.5em - 1px);
  padding-left: calc(0.75em - 1px);
  padding-right: calc(0.75em - 1px);
  padding-top: calc(0.5em - 1px);
  position: absolute;
  vertical-align: top;
  max-width: 300px;
  &.is-small {
    max-width: 130px;
  }
}

.StripeElement {
  padding-bottom: calc(0.8em - 1px);
  padding-left: calc(0.8em - 1px);
  padding-right: calc(0.8em - 1px);
  padding-top: calc(0.8em - 1px);
}
.color-picker {
  height: 4em;
}

button {
  .uploader {
    height: 4em;
  }
}
