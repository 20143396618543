header {
  color: #1e3770;
  background: #00cccc;
  padding: 8px 25px 10px;
  font-size: 21px;
  height: 55px;
  line-height: 1;
  font-weight: bold;
  text-transform: uppercase;
  span {
    margin-left: 40px;
    color: white;
    span {
      margin-left: 1px;
      color: $dark-yellow;
    }
  }
  img {
    position: absolute;
    max-width: 150px;
    top: 7px;
    max-height: 45px;
    overflow: hidden;
    padding-left: 10px;
  }
  a:not(:first-of-type) {
    position: relative;
    left: 200px;
    top: 17px;
    font-size: 16px;
    color: white;
    opacity: 0.85;
    padding-right: 60px;
    &:hover {
      opacity: 1;
    }
  }
}
