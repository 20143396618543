body {
  font-family: 'Lato', sans-serif;
}

h2 {
  font-size: 32px;
}

body {
  color: $blue;
}

.is-bold {
  font-weight: bold;
}

.subtitle {
  .is-bold {
    font-weight: bold;
  }
}
p {
  .form-error {
    font-weight: lighter;
    color: red;
  }
}

::-moz-selection {
  color: $yellow;
  background: $blue;
}
::selection {
  color: $yellow;
  background: $blue;
}
