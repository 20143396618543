.gift-card-preview-wrapper {
  width: 393px;
  height: 247px;
  position: relative;
  flex-wrap: wrap;
  .value-badge {
    color: black;
    position: absolute;
    top: 15px;
    right: 30px;
    font-size: 24px;
  }
  img {
    border-radius: 4px;
  }
  h3 {
    color: black;
    font-weight: bold;
    font-size: 18px;
    text-transform: uppercase;
    line-height: 1;
    position: absolute;
    bottom: 68px;
    left: 20px;
  }
  h4 {
    color: black;
    line-height: 1;
    text-transform: uppercase;
    position: absolute;
    font-size: 13px;
    bottom: 52px;
    left: 20px;
  }
}
.card-container {
  display: flex;
  flex-wrap: wrap;
}
.selectedCard {
  border: 2px solid teal;
  border-radius: 25px;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
