.profile-widget {
  display: flex;
  opacity: 1;
  position: absolute;
  right: 40px;
  top: 8px;
  z-index: 9999;
  .avatar-wrapper {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    padding: 0;
    line-height: 0;
    box-shadow: 0 0 0 1px rgba(89,105,129,.1), 0 1px 3px 0 rgba(89,105,129,.1), 0 1px 2px 0 rgba(0,0,0,.05);
    border: 1.5px solid white;
    cursor: pointer;
    img, svg {
      top: 0;
      position: relative;
      width: 100%;
      height: auto;
    }
    svg path {
      fill: white;
    }
    img {
      border-radius: 50%;
    }

    &:hover {
      opacity: 0.97;
    }
  }
  .profile-widget-body {
    display: none;
    background: white;
    width: 350px;
    border: 1px solid #e3e3e3;
    position: absolute;
    right: -20px;
    top: 50px;
    display: none;
    border-radius: 5px;
    footer {
      display: block;
      background: #eee;
      padding: 5px 10px;
      text-align: right;
      .button {
        font-size: 10px;
      }
    }
    .profile-avatar-wrapper {
      width: 110px;
      padding: 8px 20px;
      display: inline-block;
      img {
        position: relative;
        width: 100%;
        border-radius: 50%;
      }
      svg {
        border: 1px solid #00CCCC;
        border-radius: 50%;
      }
      svg path {
        fill: #00CCCC;
      }
    }
    .profile-info-container {
      min-height: 80px;
      vertical-align: top;
      margin: 10px 0;
      display: inline-block;
      span {
        display: block;
        font-weight: bold;
        font-size: 16px;
        padding: 2px;
        margin: 10px 5px 8px;
        color: black;
        text-transform: initial;
      }
      small {
        display: block;
        color: black;
        font-size: 14px;
        padding: 2px;
        color: #9b9c9a;
        margin: 2px 5px;
        text-transform: initial;
      }
    }

  }

  &.is-open {
    .profile-widget-body {
      display: block;
    }
  }
}
