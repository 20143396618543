.with-embedded-img {
  padding: 3px 7px;
  display: inline-block;
  span {
    display: inline-block;
    font-weight: bold;
    vertical-align: top;
    padding: 3.5px 8px 5px 6px;
  }
  img {
    width: 24px;
    margin: 4px;
    border-radius: 4px;
  }
}

.button.is-primary {
  color: $blue;
  background: $yellow;
  &:hover {
    background: $blue;
  }
}

.button {
  &.is-disabled {
    opacity: 0.4;
    cursor: disabled;
  }
}
