nav.sidebar {
  flex: 0 0 270px;
  background-color: #eaeaef;
  order: -1;
  padding-top: 10px;
  ul {
    li {
      display: block;
      padding: 0;
      line-height: 1;
      a {
        font-size: 17px;
        color: #444444;
        box-shadow: 0 0 0 1px rgba(89, 105, 129, 0.1),
          0 1px 3px 0 rgba(89, 105, 129, 0.1),
          0 1px 2px 0 rgba(0, 0, 0, 0.05);
        border-bottom: 1px solid #444444;
        box-sizing: border-box;
        display: block;
        padding: 10px 14px;
        width: calc(100% - 20px);
        border-radius: 4px;
        background: rgba(255, 255, 255, 1);
        margin: 10px;
        span {
          font-weight: bold;
          color: #444444;
          margin-left: 3px;
        }
        transition: all 0.1s ease-in;
        &:hover {
          background: #00cccc;
        }
        text-decoration: none;
      }
    }
  }
}
