.layout-wrapper {
  display: flex;
  flex-direction: column;
}

.layout-body {
  display: flex;
  background: white;
  flex: 1;
  min-height: calc(100vh - 45px);
}

.layout-content, .layout-wrapper {
  flex: 1;
}

.layout-content {
  padding: 50px;
}
