.user-card {
  border: 1px solid #e2e2e2;
  display: inline-block;
  padding: 12px 20px;
  border-radius: 5px;
  margin: 12px;
  strong {
    display: block;
    font-size: 16px;
  }
  div {
    font-size: 14px;
  }
}
.order-card {
  border: 1px solid #e3e3e3;
  display: flex;
  small {
    position: relative;
    top: -10px;
    font-size: 12px;
    text-transform: uppercase;
    color: #a3a3a3;
  }
  .order-body {
    margin: 12px 30px;
  }
  .order-info {
    strong {
      display: inline-block;
      margin-right: 8px;
      min-width: 70px;
    }
    background: #f9f8f7;
    padding: 10px;
    border-radius: 8px;
  }
  .icon-wrapper {
    svg {
      path {
        stroke: #FFDE00 !important;
      } 
    }
    text-align: center;
    width: 80px;
    margin-right: 20px;
  }
}
.order-code {
  font-family: monospace;
  color: $blue;
  text-transform: uppercase;
  font-size: 13px;
}
