$turquoise: #00cccc;
$dark-turquoise: #17fff9;
$light-turquoise: #00cec9;
$yellow: #ffde00;
$dark-yellow: #fedc31;
$blue: #1e3770;
$light-blue: #3c5ba2;
$light-blue2: #2159d8;
$gray-text: #96999a;
$black: #000;
$trend-white: #eaeaef;

$gradients: () !default;
$gradients: map-merge(
  (
    light-turquoise:
      linear-gradient(
        135deg,
        $dark-turquoise 0%,
        $light-turquoise 100%
      ),
    turquoise:
      linear-gradient(135deg, $turquoise 0%, $light-turquoise 100%),
    blue: linear-gradient(135deg, $blue 0%, $light-blue 100%),
    light-blue:
      linear-gradient(95deg, $light-blue 0%, $light-blue2 100%),
    yellow: linear-gradient(135deg, $yellow 0%, $dark-yellow 100%),
    chill: linear-gradient(135deg, #09e485 0%, #0291e3 100%),
    warm: linear-gradient(135deg, #f2cd48 0%, #fd803f 100%),
    hot: linear-gradient(135deg, #f9445a 0%, #fd803f 100%),
    cold: linear-gradient(135deg, #8019c6 0%, #2f93cc 100%),
  ),
  $gradients
);

@mixin bg-gradient($parent, $gradient) {
  #{$parent} {
    background: $gradient;
  }
}

@mixin text-gradient($parent, $gradient) {
  #{$parent} {
    background-image: $gradient;
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
  }
}

@mixin text-gradient($parent, $gradient) {
  #{$parent} {
    background-image: $gradient;
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
  }
}

@each $gradient, $value in $gradients {
  @include bg-gradient('.bg-gradient-#{$gradient}', $value);
}

@each $gradient, $value in $gradients {
  @include text-gradient('.text-gradient-#{$gradient}', $value);
}
