.page-enter {
  .onboarding-card {
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
    * {
      opacity: 0;
      transform: scale(0.9);
      transition: opacity 300ms, transform 300ms;
    }
  }
}

.page-enter-active {
  .onboarding-card {
    transform: scale(1);
    transition: opacity 300ms, transform 300ms;
    * {
      opacity: 1;
      transform: scale(1);
      transition: opacity 300ms, transform 300ms;
    }
  }
  transition: opacity 300ms, transform 300ms;
}

.page-exit {
  position: absolute;
  opacity: 0;
  .onboarding-card {
    transform: scale(1);
    transition: opacity 300ms, transform 300ms;
    * {
      opacity: 1;
      transform: scale(1);
      transition: opacity 300ms, transform 300ms;
    }
  }
}

.page-exit-active {
  position: absolute;
  opacity: 0;
  .onboarding-card {
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
    * {
      opacity: 0;
      transform: scale(0.9);
      transition: opacity 300ms, transform 300ms;
    }
  }
}

.onboarding-card {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 850px;
  margin: auto;
  .content-column {
    padding: 40px 30px;
  }
  p {
    color: $gray-text;
  }
  small {
    display: block;
    padding: 6px 2px;
    color: $gray-text;
  }
  input {
    max-width: 350px;
  }
  .title {
    height: 88px;
  }
  .subtitle {
     margin-top: 50px;
  }
  .columns {
    min-height: 340px;
  }
  label {
    color: $black;
  }
  .skip-button {
    border: 1px solid white;
  }

}
