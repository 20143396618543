.share-links-collection {
  .box {
    min-height: 235px;
  }
  .card {
    padding: 30px;

    .is-4 {
      font-size: 21px;
      padding: 9px 0;
    }
    .is-5 {
      font-size: 16px;
    }
  }
}
