.purchase-page {
  background-color: white;
  border-radius: 8px;
  width: 1050px;
  margin: auto;
  padding: 20px 50px 75px 50px;
  label {
    min-width: 160px;
    color: $black;
  }
  .title {
    padding-top: 30px;
    padding-bottom: 25px;
  }
  .input {
    max-width: 250px;
  }
  .button {
    text-transform: uppercase;
  }
  .card {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
