.sign-in-card {
  padding: 42px 70px 100px 70px;
  text-align: center;
  background: white;
  border-radius: 5px;
  width: 500px;
  margin: auto;
  .logo-container {
    display: inline-block;
    background-color: #00CCCC;
    width: 100px;
    height: 100px;
    margin: auto;
    border-radius: 50%;
  }
  img {
    width: 63px;
    height: 72px;
    margin: 13px;
  }
  .title {
    margin-top: 15px;
    margin-bottom: 30px;
    font-size: 31px;
  }
  .subtitle {
    font-size: 17px;
    margin-bottom: 30px;
    a {
      color: #00CCCC;
    }
  }
  input {
    font-size: 21px;
    padding: 8px 10px;
    width: 100%;
    margin-bottom: 15px;
  }
  button {
    margin-top: 20px;
    width: 100%;
    display: block;
  }
}
